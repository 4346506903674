import React from 'react'
import { useNavigate } from 'react-router-dom';
import ImagePopup from  './ImagePopup';
//import { ProgramDescription } from '../text/programDescription';
import Footer from '../navigation/Footer';

export default function Home() {
  const navigate = useNavigate();
  console.log('Home');

  function tryTheApp() {
    console.log('Try the app');
    navigate('/tryit');
  }

  return (
      <div className='lcard'>
          <div className='flex flex-col'>
              <div className='flex flex-col justify-center text-center font-bold text-2xl w-full px-5 pt-6'>
                  <p>
                      Do you have trouble remembering when (OR IF?) you took your medications?
                  </p>
                  <p>
                      This can be particularly troublesome if you take your medications on an as-needed basis.
                  </p>
                  <p>
                      If you don't remember when, or if, you could end up taking too many, or take them too often.
                  </p>
              </div>
              <div className='w-full px-20'>
                  <hr className="h-1 my-4 bg-gray-500 border-0" />
                  <div className='flex flex-col items-center justify-center'>
                      <div className='text-xl mb-6'>
                          We have found that most "medication apps" primarily remind you when to take your medications,
                          but they are difficult to use when all you want to do is record when you actually took them.
                      </div>
                      <div className='flex mx-4 items-center justify-between'>
                          <ImagePopup source='/assets/MedsScreen.png' />
                          <ImagePopup source='/assets/HistoryScreen.png' />
                          <ImagePopup source='/assets/ScheduleScreen.png' />
                          <ImagePopup source='/assets/MedDetailsScreen.png' />
                          <ImagePopup source='/assets/MedHistoryScreen.png' />
                          <ImagePopup source='/assets/TakeMedScreen.png' />
                          {/* <img className='h-[285px] w-[160px] mx-2 flex flex-col border-black border-2' alt='Med Screen' src='/assets/MedsScreen.png' /> */}
                          {/* <img className='h-[285px] w-[160px] mx-2 flex flex-col border-black border-2' alt='History Screen' src='/assets/HistoryScreen.png'/> */}
                      </div>
                  </div>
                  <div className='flex justify-center mt-4'>
                      <div
                          className='p-4 w-4/12 text-center rounded-md text-4xl font-bold bg-blue-300 hover:cursor-pointer'
                          onClick={() => tryTheApp()}
                      >
                        Try the app
                      </div>
                  </div>
                  <hr className="h-1 my-4 bg-gray-500 border-0" />
              </div>
              <div className='w-full px-20'>
                  <div className='text-2xl font-bold text-center'>
                      This program is what is called a Progressive Web Applicaiton (PWA).
                  </div>
                  <div className='mt-6 text-xl font-semibold text-left'>
                      A progressive web app (PWA) is a website that looks and behaves as if it is a mobile app. PWAs are built to take advantage of native mobile device features, without requiring the end user to visit an app store, make a purchase and download software locally. Instead, a PWA can be located with a search engine query and accessed immediately through a browser.
                      What that means is that once it has been run from the internet, it has been stored on your device (smart phone, tablet, laptop, desktop).
                      This also means that any data input to the program resides on your device and NOT on the internet. In addition it means that the program (with it's data) will continue to work even if you lose your WiFi or cellular internet.
                  </div>
              </div>
              <div className='h-10'></div>
          </div>
          <Footer />
      </div>
  )
}

