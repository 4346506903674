import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import meds from './icons/pills-50.png';
import history from './icons/history-48.png';
import schedule from './icons/schedule-50.png';
import menu from './icons/menu-48.png';
import face from './icons/face-48.png';
import ingredients from './icons/ingredients-rx-48.png';



export default function BottomNav() {
  const navigate = useNavigate();

  const tabStyle = 'flex flex-col text-center justify-center items-center';
  const tabIcon = 'h-6 w-6';
  const tabText = 'text-sm font-bold';

  function navTo(target) {
      navigate(target);
  }

  function Tab({icon,name,target}) {
    return (
      <div className={tabStyle} onClick={() => navTo(target)}>
        <img className={tabIcon} src={icon} alt='' />
        <div className={tabText}>{name}</div>
      </div>
    )
  }

  return (
    <>
    <section id='bottom-navigation' className='block fixed w-[500px] bottom-0 z-10 bg-white shadow border-2 rounded-md border-slate-400'>
      <div id='tabs' className='flex justify-around mt-1'>
          <Tab icon={meds} name={'Meds'} target={'/meds'} />
          <Tab icon={history} name={'History'} target={'/hist'} />
          <Tab icon={schedule} name={'Schedule'} target={'/schedule'} />
      </div>
    </section>
    </>
  );
}
