import React from 'react'

export default function Footer() {
  return (
    <div style={footerContainer} className='bg-slate-700'>
      <div className='flex flex-row w-full h-12 items-center px-4 justify-between'>
            <div className='text-gray-400 text-sm'>
              A great app brought to you by a programmer that does not get much sleep!
            </div>
            <div>
              <button className='bg-slate-400 rounded-md px-2 float-right'>Privacy Policy</button>
            </div>

      </div>
    </div>
  );
}

const footerContainer = {
    width: '100%',
    height: 50,
    position: 'absolute',
    bottom: 0,
    left: 0,
  };
