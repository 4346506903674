import { getHistFromFB, getMedsFromFB } from "../common/apiHelpers";
import { sortHistory, sortMedications } from "../common/medicationHelpers";
import { getUserDataState, initialUserDataState, setHist, setMeds, setUserDataStateLoaded, updateUserDataState } from "../store/storeValtio";

export async function loadUserData(userId) {
    const dataState = getUserDataState();
    if (!userId) return dataState;

    console.log('loadUserData 0', dataState);
    if (dataState.loaded) return dataState;
    
    let response = await getMedsFromFB(userId);
    let meds = await response;
    sortMedications(meds);
    setMeds(meds);

    response = await getHistFromFB(userId);
    let hist = await response;
    sortHistory(hist);
    setHist(hist);

    const newDataState = {...initialUserDataState};
    newDataState.meds = meds;
    newDataState.hist = hist;
    newDataState.loaded = true;

    updateUserDataState(newDataState);
    console.log('loadUserData 1', newDataState);
    return(getUserDataState())
}