import { proxy, useSnapshot } from "valtio";
var _ = require('lodash');

const initialOnlineState = {
  online: false
}

export const initialUserState = {
  loaded: false,
  authenticated: false, 
  currentUser: {}, 
  currentUserId: null,
};

export const userState = proxy(initialUserState);

export const resetUserState = () => {
  userState.loaded = initialUserState.loaded;
  userState.authenticated = initialUserState.authenticated;
  userState.currentUser = initialUserState.currentUser;
  userState.currentUserId = initialUserState.currentUserId;
};

export const updateUserState = (newState) => {
  userState.loaded = newState.loaded;
  userState.authenticated = newState.authenticated;
  userState.currentUser = newState.currentUser;
  userState.currentUserId = newState.currentUserId;
};

export function useUserStateSnapshot() { return useSnapshot(userState); }

export const getCurrentUserRegistrationId = () => {return userState.currentUser.RegistrationId};
export const getCurrentUserName = () => {return userState.currentUser.displayName};
export const getCurrentUserAwakeTime = () => {return userState.currentUser.awakeTime ? userState.currentUser.awakeTime : ''};
export const getCurrentUserBedTime = () => {return userState.currentUser.bedTime ? userState.currentUser.bedTime : ''};
export const getCurrentUser = () => {return userState.currentUser};
export const setCurrentUser = (u) => {userState.currentUser = u};
export const getCurrentUserId = () => {return userState.currentUserId};
export const setCurrentUserId = (u) => {userState.currentUserId = u};
export const getAuthenticated = () => {return userState.authenticated};

export const getUserStateLoaded = (l) => {return userState.loaded};
export const setUserStateLoaded = (l) => {userState.loaded = l};

export const initialUserDataState = {
  loaded: false, 
  meds: [], 
  hist: [], 
};

export const userDataState = proxy(initialUserDataState);

export const useUserDataStateSnapshot = () => {return useSnapshot(userDataState)};

export const resetUserDataState = () => {
  userDataState.loaded = initialUserDataState.loaded;
  userDataState.meds = initialUserDataState.meds;
  userDataState.hist = initialUserDataState.hist;
};

export const getUserDataState = () => { return userDataState };
export const setUserDataStateLoaded = (tf) => { userDataState.loaded = tf};
export const getUserDataStateLoaded = () => { return userDataState.loaded };
export const setMeds = (m) => { userDataState.meds = m};
export const getMeds = () => { return userDataState.meds};
export const setHist = (m) => { userDataState.hist = m};
export const getHist = () => { return userDataState.hist};

export const updateUserDataState = (newState) => {
  userDataState.meds = newState.meds;
  userDataState.hist = newState.hist;
  userDataState.loaded = newState.loaded;
};

