import React, { useState } from 'react';
import './ImagePopup.css';

export default function ImagePopup({source}) {
    const [isOpen, setIsOpen] = useState(false);
    //console.log('ImagePopup', source);
    const handleShowDialog = () => {
      setIsOpen( !isOpen );
      //console.log('image cliked');
    };
  
    return (
    <div className='w-[15%]'>
        <img 
            className='mx-2 border-2 border-black rounded-md' 
            alt='Med Screen' 
            src={source}
            width="auto"
            height="auto"
            onClick={()=>handleShowDialog()}
            />
        {isOpen && (
        <dialog
            className="dialog"
            style={{ position: 'absolute' }}
            onClick={()=>handleShowDialog()}
            open
        >
        <img 
            className='image border-2 border-black rounded-md' 
            alt='Med Screen' 
            src={source}
            onClick={handleShowDialog}
            />
        </dialog>
        )}
    </div>
    )
}

