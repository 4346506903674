import moment from 'moment';

export function generateUniqueId(){
    // Alphanumeric characters
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let autoId = '';
    for (let i = 0; i < 20; i++) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length));
    }
  
    return autoId;
  }

  // julian date
export function getJD(date) {
    var M = date.month()+1;
    var D = date.date()+1;
    var Y = date.year();
    if (M <= 2) {
      --Y;
      M += 12;
    }
  
    let A = Math.floor(Y/100);
    let B = Math.floor(A/4);
    let C = 2-A+B;
    let E = Math.floor(365.25 * (Y+4716));
    let F = Math.floor(30.6001 * (M+1));
    let JD = C+D+E+F-1524;
    //console.log(M, D, Y, JD);
    return Math.floor(JD);
  }

  export function getNowISO() {
    return new Date().toISOString();
  }
  
  export function formatTime(ts) {
    return moment(ts).format('llll');
  }
  
  export function formatTimeShort(ts) {
    return moment(ts).format('dd, MM/DD h:mm A');
  }
  
  // convert HH:MM AM to minutes since midnight
export function timeToMinutes(time) {
  if (!time) return 0;
  let min = 0;
  let hrs = 0;
  let parts = time.split(':');
  if (parts.length === 2) {
    hrs = parseInt(parts[0]);
    min = parseInt(parts[1]);
    if ( hrs === 12 ) hrs = 0;
    if (time[6] === 'P' ) min += 720;
    min += hrs * 60;
  }
  //console.log(time, min)
  return min;
}
  
  // convert minutes to HH:MM xM
  export function minutesToTime(minutes) {
    let ampm = ' AM';
    if (minutes >= 720) {
      minutes -= 720;
      ampm = ' PM';
    }
    const hrs = minutes / 60;
    const min = minutes % 60;
    //console.log(minutes, hrs, min);
  
    return twoDigits(hrs) + ':' + twoDigits(min) + ampm;
  }
  
  export function twoDigits(n) {
    return n > 9 ? '' + n : '0' + n;
  }
  
  // get minutes since midnight from moment
  export function minutesSinceMidnight(mmt) {
    const msm = (mmt.hours() * 60) + mmt.minutes();
    //console.log('msm', mmt, msm )
    return msm;
  }
  
  // convert HH:MMxM to moment
  // adds minutes to current date (as of midnight)
  export function timeToMoment(time) {
    if (!time) return null;
    const min = timeToMinutes(time);
    let date = moment().startOf('day').add(min, 'm');
    //console.log('ttm', time, min, date);
    return date;
  }
  
  export function timeToDate(time) {
    let date = timeToMoment(time).toDate();
    return date;
  }
  
  export function dayOfWeek(weekday) {
    switch (weekday) {
      case 'Sunday':
        return 0;
      case 'Monday':
        return 1;
      case 'Tuesday':
        return 2;
      case 'Wednesday':
        return 3;
      case 'Thursday':
        return 4;
      case 'Friday':
        return 5;
      case 'Saturday':
        return 6;
      default:
        return -1;
    }
  }
  
  export function timeGrid() {
    var d = moment().set('hour', 0).set('minute', 0).set('seconds', 0);
    var tg = [];
    tg.push(d.format('hh:mm A'));
    for (var i = 0; i < 95; i++) {
      d.add(15, 'minutes');
      //    var nd = moment(JSON.parse(JSON.stringify(d)));
      tg.push(d.format('hh:mm A'));
    }
    return tg;
  }
  
  export function parseForm(form) {
    if (!form || form.nodeName !== 'FORM') {
      return;
    }
    var i,
      j,
      q = [],
      o = {};
    for (i = form.elements.length - 1; i >= 0; i = i - 1) {
      if (form.elements[i].name === '') {
        continue;
      }
      switch (form.elements[i].nodeName) {
        case 'INPUT':
          switch (form.elements[i].type) {
            case 'text':
            case 'hidden':
            case 'email':
            case 'password':
            case 'button':
            case 'reset':
            case 'submit':
              o[form.elements[i].name] = form.elements[i].value;
              q.push(form.elements[i].name + ':' + form.elements[i].value);
              break;
            case 'checkbox':
            case 'radio':
              o[form.elements[i].name] = form.elements[i].checked ? true : false;
              q.push(form.elements[i].name + (form.elements[i].checked ? ':true' : ':false'));
              break;
            default:
              break;
          }
          break;
        case 'file':
          break;
        case 'TEXTAREA':
          q.push(
            form.elements[i].name +
              ':' +
              form.elements[i].value
          );
          break;
        case 'SELECT':
          switch (form.elements[i].type) {
            case 'select-one':
              o[form.elements[i].name] = form.elements[i].value;
              q.push(form.elements[i].name + ':' + form.elements[i].value);
              break;
            case 'select-multiple':
              for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
                if (form.elements[i].options[j].selected) {
                  q.push(
                    form.elements[i].name +
                      ':' +
                      form.elements[i].options[j].value
                  );
                }
              }
              break;
            default:
              break;
          }
          break;
        case 'BUTTON':
          switch (form.elements[i].type) {
            case 'reset':
            case 'submit':
            case 'button':
              q.push(
                form.elements[i].name +
                  ':' +
                  form.elements[i].value
              );
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    }
    //data = q.join('&');
    return o;
  }
  
  export function appIsInstalled() {
    // For iOS
    if(window.navigator.standalone) return true
  
    // For Android
    if(window.matchMedia('(display-mode: standalone)').matches) return true
  
    // If neither is true, it's not installed
    return false
  }

export function validateEmail(email) {
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const isValid = emailPattern.test(email);
  return isValid;
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
