import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserId } from '../store/storeValtio';

export default function MainScreen() {
  const currentUserId = getCurrentUserId();
  console.log('MainScreen', currentUserId);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUserId) 
      navigate('/meds');
    else
      navigate('/home');
  });

  return;
}
