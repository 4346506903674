import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { lookupMed } from '../../common/medicationHelpers';
import HistoryItem from './HistoryItem';
//import chevLeft from 'icons/chevron-left-24.png';
import { getHist } from '../../store/storeValtio';

export default function HistoryList(props) {
  const params = useParams();
  const medId = params.id;
  const navigate = useNavigate();
  const hist = getHist();
  var med = null;
  if (medId) med = lookupMed(medId);

  //console.log('HistoryList', medId, med);

  // useEffect(() => {
  //   window.startup();
  //   return () => {
  //     window.shutdown();
  //   }
  // }, [])

  function exit() {
    navigate(-1);
  }

  // if history for just one med, filter it out
  var histList = null;
  if ( medId ) {
    histList = hist.filter(h => h.medicationId === medId);
  } else {
    histList = hist;
  }

  if (histList.length === 0) {
    var msg = "???";
    if (!medId) {
      msg = 'There is no medication history';
    } else {
      msg = 'There is no history for this medication';
    }
    return(
      <div className='w-full flex flex-col justify-center font-semibold text-xl text-center my-8'>
          {msg}
      </div>
    )
  }

  return (
    <div className='mcard'>
      {medId && 
      <div className='mcard-header'>
          <div className='pl-2 w-full font-bold text-xl text-left'>
            {med.name}
          </div>
      </div>
      }
      <div id='scroll_area' className='lcard mx-4 mb-1'>
      {histList.map((h, ix) => {
        var medName = null;
        if (!medId) {
          med = lookupMed(h.medicationId);
          medName = med.name;
        }
        if (!med) {
          // should never happen
          console.log("Med NOT FOUND: ", h.medicationId);
          return(<div>Med Not Found: {h.medicationId} (h.historyId)</div>);
        }

        return (
        <HistoryItem medName={medName} hist={h} key={ix} />
        )
      })}
    </div>

      <div>
        <div style={{ height: '60px' }} />
      </div>
    </div>
  );
}
