import React, {useEffect, useState} from 'react';
import { Routes, Route } from 'react-router-dom';
import MainScreen from '../screens/MainScreen';
import ProtectedRoute from '../ProtectedRoute';
import Registered from '../registered/Registered';
import LoginForm from '../registered/LoginForm';
import UserMenu from './UserMenu';
import MedicationList from '../userPages/medications/MedicationList';
import BottomNav from './BottomNav';
//import Footer from './Footer';
import HistoryList from '../userPages/history/HistoryList';
import Schedule from '../userPages/schedule/Schedule';
import Home from '../screens/Home';
import TryIt from '../screens/TryIt';
import { getRandomInt } from '../common/generalHelpers';
import RegData from '../userPages/RegData';


export default function Dashboard() {
  console.log('Dashboard', window.location.href);
  const currentUserId = localStorage.getItem('currentUser');
  console.log('Dashboard user', currentUserId);
  const random = getRandomInt(100);
  console.log('Dashboard random', random);


  return (
    <div id='dashboard'>
        <div className='main'>
          <Routes>
            <Route path="/home" element={<Home />}/>
            <Route path="/tryit" element={<TryIt />}/>
            <Route path="/login" element={<LoginForm />}/>
            {/* <Route path="/usermenu" element={<ProtectedRoute><UserMenu /><BottomNav /></ProtectedRoute>}/> */}
            <Route path="/meds" element={<ProtectedRoute><RegData><MedicationList /></RegData></ProtectedRoute>}/>
            <Route path="/hist" element={<ProtectedRoute><RegData><HistoryList /></RegData></ProtectedRoute>}/>
            <Route path="/hist/:id" element={<ProtectedRoute><RegData><HistoryList /></RegData></ProtectedRoute>}/>
            <Route path="/schedule" element={<ProtectedRoute><RegData><Schedule /></RegData></ProtectedRoute>}/>
            <Route path="/registered" element={<ProtectedRoute><Registered /></ProtectedRoute>}/>

            <Route path="*" element={<ProtectedRoute><MainScreen /></ProtectedRoute>} />  
          </Routes>
        </div>
        
    </div>
);
}
