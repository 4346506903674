import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatTime } from '../../common/generalHelpers';
import { formatTaken } from '../../common/medicationHelpers';

export default function HistoryItem({medName, hist}) {
  //console.log('HistoryItem', medName);
  const navigate = useNavigate();

  function showMedHist(id) {
    console.log('showMedHist', id);
    navigate('/hist/' + id);
  }

  const t = formatTime(hist.date);
  const ltAgo = formatTaken(hist);
  //const td = `Taken ${ltAgo} at ${t}`;

  return (
    <div className='mcard border-2 border-gray-400 mb-1' key={hist.historyId}>
      {medName &&
      <div 
        className='mcard-header hover:cursor-pointer'
        onClick={()=>showMedHist(hist.medicationId)}
        >
          {medName}
        </div>
      }
      <div className='histItem'>
        <div>
          {ltAgo}
          <br />
          {t}
        </div>
      </div>
    </div>
  );
}