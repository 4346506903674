import React, { useEffect, useMemo, useState } from 'react';
import Spinner from '../../common/Spinner';
import MedicationListItem from './MedicationListItem';
import { getLatestHistory, sortMedications } from '../../common/medicationHelpers.js'
import { useNavigate } from 'react-router-dom';
import { getCurrentUserId, getMeds, getUserDataState, subscribeUserDataState, useUserDataState, useUserDataStateSnapshot } from '../../store/storeValtio';

export default function MedicationList() {
  const navigate = useNavigate();
  const dataState = useUserDataStateSnapshot();
  console.log('MedicationList', dataState);


  if (!dataState.loaded) return (<Spinner />);

  return (
    <div className='mcard'>
      {dataState.meds.length === 0 &&
      <div className='w-full flex flex-col justify-center'>
        <div className='text-center my-8'>
          You do not have any medications entered
          <br/>
          Use the link below to add a medication:
        </div>
        <div className='w-full flex justify-center'>
        <button 
          className='bg-blue-200 w-4/12 p-4 rounded-md'
          onClick={()=>navigate('/create')}
        >Add medication</button>
        </div>
      </div>
      }
      {/* <div className='text-lg font-medium text-green-600 bg-gray-200 border rounded mb-2 p-2'>{dt}</div> */}
      <div id='scroll_area' className='lcard'>
        {dataState.meds.map((m,ix) => {
          const latest = getLatestHistory(m.medicationId);
          //console.log('ML latest', m.medicationId, latest);
          return (<MedicationListItem med={m} latestHist={latest} key={ix} />);
        })}
      </div>
    </div>
  );
}
