import React from 'react';
import { createRoot } from 'react-dom/client';
//import ReactDOM from 'react-dom/client';
//import { Router } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
//import history from './store/history';
import './index.css';
import App from './App';

console.log('index.js');

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  // <Router history={history} >
  <Router>
    <App />
  </Router>
);
