import React, { useMemo } from 'react';
import { useLocation } from "react-router-dom";
import NavBar from './navigation/NavBar';
//import Footer from './navigation/Footer';
import Dashboard from './navigation/Dashboard';
import { loadUserData } from './userPages/loadUserData';
import { getCurrentUserId, setCurrentUserId, useUserDataStateSnapshot } from './store/storeValtio';
import Spinner from './common/Spinner';

export default function App() {
  const userData = useUserDataStateSnapshot();
  const currentUserId = localStorage.getItem('currentUser');
  setCurrentUserId(currentUserId);
  console.log('App', currentUserId, getCurrentUserId());

  const location = useLocation();
  useMemo(() => {
    console.log('UM route',location.pathname);
    console.log('UM userData',userData.loaded);
  }, [location.pathname, userData.loaded])

  if (currentUserId) {
    loadUserData(currentUserId);
    if (!userData.loaded) return (<Spinner />);
  }

  return (
    <div className="flex flex-col w-full h-full">
      <NavBar />
      <Dashboard />
    </div>
  );
}

