const apiURL = 'https://api.itookitwhen.com/';

export async function getMedsFromFB(regId) {
    const url = apiURL + 'loadMeds/' + regId;
    const response = await getDataAPI(url);
    //console.log('loadMeds response', response);
    const result = await response;
    //console.log('loadMeds result', result);

    return result;
}

export async function getHistFromFB(regId) {
    // get most recent 30 days
    const url = apiURL + 'loadHistCurrent/' + regId + '/30';
    const response = await getDataAPI(url);
    //console.log('loadHist response', response);
    const result = await response;
    //console.log('loadHist result', result);

    return result;
}

export async function login(userName, password) {
    const url = apiURL + 'login';
    const postData = {
        email: userName,
        password: password
    }

    const response = await postDataAPI(url, postData);
    //console.log('login response', response);
    const result = await response;
    //console.log('login result', result);

    return result;
}

export async function getDataAPI(url) {
    console.log('getDataAPI', url);
    const response =  await fetch(url, {
      method: 'GET', 
      mode: 'cors', 
      headers: {"Content-Type": "application/json"},
      });
    if (response.status !== 200) {
        console.log("getDataAPI error:", response.status);
        return null;
    }
    const data = await response.json();
    //console.log('Fetch data:', data);
    return data;
  }
  
export async function postDataAPI(url, json) {
    const jsonData = JSON.stringify(json);
    console.log('POSTDataAPI', jsonData);
    let response =  await fetch(url, {
      method: 'POST', 
      mode: 'cors', 
      headers: {"Content-Type": "application/json"},
      body: jsonData
      })
    //console.log("POSTDataAPI response:", response);
    if (response.status !== 200)
    {
        console.log("POSTDataAPI error:", response.status);
        return null;
    }
    let result = await response.json()
    //console.log("POSTDataAPI result:", result);
    return result;
}
