import React from 'react'
import BottomNav from '../navigation/BottomNav'

export default function RegData({children}) {
  return (
    <div className='flex flex-col justify-center items-center'>
        <div className='w-[500px]'>
            {children}
            <BottomNav />
        </div>
    </div>
  )
}
